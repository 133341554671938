<template>
  <div class="govuk-grid-row">
    <slot />
  </div>
</template>

<script>
export default {
  name: "GovGridRow"
};
</script>
